import React, { useState } from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import { useTranslation } from "react-i18next";

type FileUploadModalProps = {
  isbn?: String;
  close: () => void;
  isOpen: boolean;
};

const FileUploadModal: React.FC<FileUploadModalProps> = ({
  isbn,
  close,
  isOpen,
}) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const [shortDescription, setShortDescription] = useState<string>("");
  const [longDescription, setLongDescription] = useState<string>("");
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    try {
      setUploading(true);
      setUploadError(false);
      const formData = new FormData();
      if (file) {
        formData.append("file", file);
      }
      formData.append("shortDescription", shortDescription);
      formData.append("longDescription", longDescription);

      try {
        await axios.post(`/api/files/${isbn}/upload`, formData);
        close(); // Close the modal
      } catch (err) {
        console.error("File upload failed");
        setUploadError(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setUploadError(true);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => close()}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload File</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            {uploadError && (
              <Alert status="error" marginTop={4}>
                <AlertIcon />
                <AlertTitle>{t("fileUploadError")}</AlertTitle>
              </Alert>
            )}
            <FormControl>
              <FormLabel>Upload File</FormLabel>
              <Input type="file" onChange={handleFileChange} />
            </FormControl>
            <FormControl>
              <FormLabel>Short Description</FormLabel>
              <Input
                type="text"
                placeholder="Enter short description"
                value={shortDescription}
                onChange={(e) => setShortDescription(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Long Description</FormLabel>
              <Textarea
                placeholder="Enter long description"
                value={longDescription}
                onChange={(e) => setLongDescription(e.target.value)}
              />
            </FormControl>
            <Button
              colorScheme="blue"
              onClick={handleSubmit}
              disabled={uploading}
              isLoading={uploading}
            >
              Submit
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FileUploadModal;
